#activeCall {
    display : none;
    position : absolute;

    top : 0;
    left : 0;
    width : 100%;
    height : 100%;

    z-index: 9999;

    pointer-events: none;

    &.active {
        box-sizing: border-box;
        padding : 1ex; 

        display : flex;
        flex-direction: column;

        pointer-events: initial;
        background : #0b5248;
    }

    .localvideo {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

        display : none;
        left : 1ex;
    }

    .tableContainer {
        margin : 1em;
        flex : 1 1 auto;
    }

    video {
        position: absolute;

        right : 1ex;
        bottom : 1ex;

        width : 200px;
    }

    textarea {
        position: absolute;

        left : 0;
        width : 100%;
        height : 50%;
    }

    .controls {
        display: flex;
        justify-content : center;        
    }
}